import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <p id="servicesParagraph">
            {props.data ? props.data.paragraph : "Loading"}
          </p>
        </div>
        <div className="services-container">
          {props.data
            ? props.data.content.map((d, i) => (
                <div key={`${d.name}-${i}`} className="service-single">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
