import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="features-container">
        <div className=" section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
        </div>
        <div className="services-container">
          {props.data
            ? props.data.content.map((d, i) => (
                <div key={`${d.title}-${i}`} className="service-single">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
