import React, { useContext, useState, useRef, useEffect } from "react";
import { IoChevronDown } from "react-icons/io5";
import "./LanguageSelectorMain.css";
import { languageContext, useLanguage } from "../../contexts/LanguageProvider";

function LanguageTab({
  lang,
  additionalClasses = "",
  isOnClick = true,
  outsideAdditionalClass = "",
}) {
  return (
    <div
      className={"lang-item no-select" + " " + additionalClasses}
      onClick={isOnClick ? lang.onClick : () => {}}
    >
      <p className={"lang-item__code no-select" + " " + outsideAdditionalClass}>
        {lang.languageCode}
      </p>
    </div>
  );
}

function LanguageSelectorMain({
  languages,
  additionalClasses = "",
  innerAdditionalClasses = "",
  outsideAdditionalClass = "",
}) {
  const { isTurkish } = useLanguage();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisibility(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className={`dropdown-outer no-select ${additionalClasses}`}
      onClick={() => {
        setMenuVisibility((x) => !x);
      }}
    >
      <div className="selected-lang-container">
        {isTurkish ? (
          <LanguageTab
            lang={languages[1]}
            isOnClick={false}
            outsideAdditionalClass={outsideAdditionalClass}
          />
        ) : (
          <LanguageTab
            lang={languages[0]}
            isOnClick={false}
            outsideAdditionalClass={outsideAdditionalClass}
          />
        )}
        <IoChevronDown
          size={18}
          color="white"
          className={`dropdown-outer__icon ${
            menuVisibility ? "rotate-180-cw" : "rotate-180-ccw"
          } ${outsideAdditionalClass}`}
          style={{ padding: "0.2rem" }}
        />
      </div>
      {menuVisibility && (
        <div
          className={`dropdown-inner ${innerAdditionalClasses} swing-in-top-fwd`}
        >
          {languages.map((lang, index) => {
            return <LanguageTab lang={lang} key={index} />;
          })}
        </div>
      )}
    </div>
  );
}

export const languageSelectorObjectCreator = ({
  name = "",
  languageCode = "",
  flagUrl: flagImg = null,
  onClick = () => {},
}) => ({
  name,
  languageCode,
  flagImg,
  onClick,
});

export default LanguageSelectorMain;
