import React from "react";
import LanguageSelectorMain, {
  languageSelectorObjectCreator,
} from "./navbar/LanguageSelectorMain";
import { useLanguage } from "../contexts/LanguageProvider";

export const Navigation = ({ outsideAdditionalClass, data }) => {
  const { changeLanguage } = useLanguage();

  const english = languageSelectorObjectCreator({
    name: "English",
    languageCode: "ENGLISH",
    onClick: () => {
      changeLanguage("EN");
    },
  });
  const turkish = languageSelectorObjectCreator({
    name: "turkish",
    languageCode: "TÜRKÇE",
    onClick: () => {
      changeLanguage("TR");
    },
  });
  const languages = [english, turkish];
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="title-with-iskur">
            <div className="iskurImage2"></div>
            <a className="nav-title page-scroll" href="#page-top">
              {data ? data.title : "Loading"}
            </a>
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {data ? data.company : "Loading"}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                {data ? data.about : "Loading"}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {data ? data.services : "Loading"}
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                {data ? data.gallery : "Loading"}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {data ? data.contact : "Loading"}
              </a>
            </li>
            <li>
              <LanguageSelectorMain
                languages={languages}
                additionalClasses="pc-only"
                // outsideAdditionalClass={outsideAdditionalClass}
              />
            </li>
            <LanguageSelectorMain
              languages={languages}
              additionalClasses="mobile-only"
              // outsideAdditionalClass={outsideAdditionalClass}
            />
          </ul>
        </div>
      </div>
    </nav>
  );
};
