// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";

// const initialState = {
//   name: "",
//   email: "",
//   message: "",
// };
export const Contact = (props) => {
  const map = (
    <iframe
      className="map"
      src={
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1773.0443180938664!2d32.335628750461595!3d41.63643939685055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409b723520a3ced7%3A0x10457ec1b422b9!2zS8O2eW9ydGFzxLEsIMSwc2tlbGUgQ2QuLCA3NDExMCBCYXJ0xLFuIE1lcmtlei9CYXJ0xLFu!5e0!3m2!1sen!2str!4v1715958109511!5m2!1sen!2str"
      }
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
  // const [{ name, email, message }, setState] = useState(initialState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setState((prevState) => ({ ...prevState, [name]: value }));
  // };
  // const clearState = () => setState({ ...initialState });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(name, email, message);

  //   {
  //     /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
  //   }

  //   emailjs
  //     .sendForm(
  //       "YOUR_SERVICE_ID",
  //       "YOUR_TEMPLATE_ID",
  //       e.target,
  //       "YOUR_PUBLIC_KEY"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         clearState();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2> {props.data ? props.data.title : "Loading"}</h2>
              {/* <p>{props.data ? props.data.paragraph : "Loading"}</p> */}
            </div>
          </div>

          <div className="contact-info-new-container">
            <h3> {props.data ? props.data.contactInfo : "Loading"}</h3>
            <div className="contact-info-new">
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i>{" "}
                    {props.data ? props.data.addressName : "Loading"}
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i>{" "}
                    {props.data ? props.data.phoneName : "Loading"}
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i>{" "}
                    {props.data ? props.data.title : "Loading"}
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>
          {map}
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a href={props.data ? props.data.instagram : "/"}>
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.twitter : "/"}></a>

                  <a
                    href={`mailto:${props.data.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-envelope-o"></i>
                  </a>
                </li>
                {/* <li>
                  <a href={props.data ? props.data.youtube : "/"}>
                    <i className="fa fa-youtube"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">{props.data.createdBy}</div>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     <div id="contact">
  //       <div className="container">
  //         <div className="col-md-8">
  //           <div className="row">
  //             <div className="section-title">
  //               <h2>Get In Touch</h2>
  //               <p>
  //                 Please fill out the form below to send us an email and we will
  //                 get back to you as soon as possible.
  //               </p>
  //             </div>
  //             <form name="sentMessage" validate onSubmit={handleSubmit}>
  //               <div className="row">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <input
  //                       type="text"
  //                       id="name"
  //                       name="name"
  //                       className="form-control"
  //                       placeholder="Name"
  //                       required
  //                       onChange={handleChange}
  //                     />
  //                     <p className="help-block text-danger"></p>
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <input
  //                       type="email"
  //                       id="email"
  //                       name="email"
  //                       className="form-control"
  //                       placeholder="Email"
  //                       required
  //                       onChange={handleChange}
  //                     />
  //                     <p className="help-block text-danger"></p>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="form-group">
  //                 <textarea
  //                   name="message"
  //                   id="message"
  //                   className="form-control"
  //                   rows="4"
  //                   placeholder="Message"
  //                   required
  //                   onChange={handleChange}
  //                 ></textarea>
  //                 <p className="help-block text-danger"></p>
  //               </div>
  //               <div id="success"></div>
  //               <button type="submit" className="btn btn-custom btn-lg">
  //                 Send Message
  //               </button>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="col-md-3 col-md-offset-1 contact-info">
  //           <div className="contact-item">
  //             <h3>Contact Info</h3>
  //             <p>
  //               <span>
  //                 <i className="fa fa-map-marker"></i> Address
  //               </span>
  //               {props.data ? props.data.address : "loading"}
  //             </p>
  //           </div>
  //           <div className="contact-item">
  //             <p>
  //               <span>
  //                 <i className="fa fa-phone"></i> Phone
  //               </span>{" "}
  //               {props.data ? props.data.phone : "loading"}
  //             </p>
  //           </div>
  //           <div className="contact-item">
  //             <p>
  //               <span>
  //                 <i className="fa fa-envelope-o"></i> Email
  //               </span>{" "}
  //               {props.data ? props.data.email : "loading"}
  //             </p>
  //           </div>
  //         </div>
  //         <div className="col-md-12">
  //           <div className="row">
  //             <div className="social">
  //               <ul>
  //                 <li>
  //                   <a href={props.data ? props.data.facebook : "/"}>
  //                     <i className="fa fa-facebook"></i>
  //                   </a>
  //                 </li>
  //                 <li>
  //                   <a href={props.data ? props.data.twitter : "/"}>
  //                     <i className="fa fa-twitter"></i>
  //                   </a>
  //                 </li>
  //                 <li>
  //                   <a href={props.data ? props.data.youtube : "/"}>
  //                     <i className="fa fa-youtube"></i>
  //                   </a>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div id="footer">
  //       <div className="container text-center">
  //         <p>
  //           &copy; 2024 AKTIP TEKNO All Rights Reserved. Created by{" "}
  //           <a href="https://www.linkedin.com/in/linkedhater2/" rel="nofollow">
  //             A_Sulaiman
  //           </a>
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );
};
