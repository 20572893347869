import { Image } from "./image";
import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title-gallery">
          <h2>{props.data ? props.data.title : "loading..."}</h2>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </div>
        <div className="present-gallery-container">
          <Swiper
            key={props.data.content.length}
            lazyPreloadPrevNext={1}
            grabCursor
            navigation={true}
            modules={[Navigation, Autoplay]}
            speed={1000}
            loop
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            className="my-swiper2"
          >
            {props.data.content.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  loading="lazy"
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="gallery-image"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
