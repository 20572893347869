import React, { createContext, useContext, useEffect, useState } from "react";
import { langs } from "./translations";
export const languageContext = createContext();
function LanguageProvider({ children }) {
  const [globalLang, setGlobalLang] = useState(langs.TR);
  useEffect(() => {
    const storedLang = localStorage.getItem("selectedLang");
    const initialLang =
      storedLang && langs[storedLang] ? langs[storedLang] : langs.TR;
    setGlobalLang(initialLang);
  }, []);

  const changeLanguage = (langCode) => {
    if (!langs[langCode]) return; // Language not supported
    localStorage.setItem("selectedLang", langCode);
    setGlobalLang(langs[langCode]);
  };
  const isTurkish = globalLang === langs.TR;

  const langProvider = {
    globalLang,
    changeLanguage,
    langs,
    isTurkish,
  };

  return (
    <languageContext.Provider value={langProvider}>
      {children}
    </languageContext.Provider>
  );
}
const useLanguage = () => {
  return useContext(languageContext);
};

export { LanguageProvider, useLanguage };
